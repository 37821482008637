import {HiOutlineArrowLongRight} from '@react-icons/all-files/hi2/HiOutlineArrowLongRight';
import {graphql, navigate} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import Seo from '../../components/Seo/Seo';
import BoxWrapper from '../../components/Wrapper/BoxWrapper';
import CardWrapper from '../../components/Wrapper/CardWrapper';
import Button from '../../components/_baseComponents/Button/Button';
import SectionHeading from '../../components/_baseComponents/SectionHeading/SectionHeading';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import useCheckBreakpoint from '../../hooks/useCheckBreakpoint';

const computerSolutionPosts = [
  {
    Image: (
      <StaticImage
        src="../../assets/services/computer-solution-1.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
        objectFit="cover"
      />
    ),
    title: 'Giải pháp Digital Workplace',
    url: '',
  },
  {
    Image: (
      <StaticImage
        src="../../assets/services/computer-solution-2.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Giải pháp ERP',
    url: '',
  },
  {
    Image: (
      <StaticImage
        src="../../assets/services/computer-solution-3.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Giải pháp CRM',
    url: '',
  },
  {
    Image: (
      <StaticImage
        src="../../assets/services/computer-solution-4.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Microsoft365',
    url: '',
  },
  {
    Image: (
      <StaticImage
        src="../../assets/services/computer-solution-5.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Google Workplace',
    url: '',
  },
  {
    Image: (
      <StaticImage
        src="../../assets/services/computer-solution-6.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Dịch vụ SEO',
    url: '',
  },
];

const programmingSolutionPosts = [
  {
    Image: (
      <StaticImage
        src="../../assets/services/programming-solution-1.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Thiết kế Website',
    url: '',
  },
  {
    Image: (
      <StaticImage
        src="../../assets/services/programming-solution-2.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Lập trình WebApp',
    url: '',
  },
  {
    Image: (
      <StaticImage
        src="../../assets/services/programming-solution-3.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Lập trình Ứng dụng di động',
    url: '',
  },
  {
    Image: (
      <StaticImage
        src="../../assets/services/programming-solution-4.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Blockchain & Web3',
    url: '',
  },
];

const cloudSolutionPosts = [
  {
    Image: (
      <StaticImage
        src="../../assets/services/cloud-solution-1.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Dịch vụ tên miền',
    url: '',
  },
  {
    Image: (
      <StaticImage
        src="../../assets/services/cloud-solution-2.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Dịch vụ Hosting',
    url: '',
  },
  {
    Image: (
      <StaticImage
        src="../../assets/services/cloud-solution-3.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Dịch vụ Cloud',
    url: '',
  },

  {
    Image: (
      <StaticImage
        src="../../assets/services/cloud-solution-4.png"
        alt="Services Thumbnail"
        width={270}
        height={150}
        className="rounded-t-lg"
        imgClassName="rounded-t-lg"
      />
    ),
    title: 'Dịch vụ DevOps',
    url: '',
  },
];

const SoftwareSolutionPage = () => {
  const {isDesktop, isTablet, isMobile} = useCheckBreakpoint();

  return (
    <BoxWrapper className={`flex flex-col gap-16 pt-[70px] md:gap-10 lg:pt-20`}>
      <div className="flex flex-col-reverse items-center justify-between pt-5 md:flex-row md:pt-10">
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-5 text-gray-800 md:max-w-[360px] lg:max-w-[600px] lg:gap-6">
            <h1 className="text-3xl font-bolder leading-[3rem] md:text-4xl md:leading-[3.5rem] lg:text-5xl lg:leading-[4.5rem]">
              Giải pháp <br />
              Công nghệ thông tin
            </h1>
            <p className="">
              Cung cấp giải pháp chuyển đổi số toàn diện trên mọi hoạt động của
              tổ chức và doanh nghiệp, cá thể hoá cho từng ngành nghề, lĩnh vực
              với phương pháp luận cụ thể cùng đội ngũ chuyên gia giàu kinh
              nghiệm
            </p>
          </div>
          <Button
            type="primary"
            content=" Liên hệ tư vấn"
            Icon={ButtonIcon}
            className="w-fit gap-2 px-6 py-3 font-medium"
            onClick={() => navigate('/lien-he')}
          />
        </div>

        {/* Image */}
        <div className="flex flex-1 flex-col items-center">
          <StaticImage
            src="../../assets/services/service-checkphone-man.png"
            alt="Services Thumbnail"
            width={500}
            className="z-[1] w-[400px] md:w-[350px] lg:w-[500px]"
            objectFit="cover"
          />

          {/* Bg effect */}
          <div className="relative before:absolute before:-left-[15vw] before:bottom-[12vh] before:-z-10 before:block before:h-96 before:w-96 before:rounded-full before:bg-blue-400/50 before:blur-[7.5rem] before:will-change-[filter]" />
          {/* Bg effect */}
          <div className="before:-z-9 relative before:absolute before:-right-[15vw] before:bottom-[22vh] before:block before:h-96 before:w-96 before:rounded-full before:bg-red-400/40 before:blur-[7.5rem] before:will-change-[filter]" />
        </div>
      </div>
      <div className="flex flex-col gap-10 lg:gap-28">
        {/* Computer Solution */}
        <div className="flex flex-col gap-10">
          <div className="flex flex-col items-center">
            <StaticImage
              src="../../assets/home/solution-computer.png"
              alt="Business Solution"
              width={160}
              height={160}
              className="h-20 w-20"
            />
            <SectionHeading
              content="Giải pháp doanh nghiệp"
              className="w-full items-center"
            />
          </div>
          <div className="flex flex-row flex-wrap justify-center gap-8">
            {computerSolutionPosts.map((post, index) => {
              const {Image, title} = post;
              return (
                <CardWrapper
                  key={index}
                  className={`${TailwindTransitions.default} flex cursor-pointer flex-col gap-1 rounded-lg bg-white/60 hover:bg-white`}
                  isShadow
                >
                  {Image}
                  <div className="p-3 text-base font-bolder text-gray-800 hover:text-primary md:text-lg">
                    {title}
                  </div>
                </CardWrapper>
              );
            })}
          </div>
        </div>

        {/* Programming Solution */}
        <div className="flex flex-col gap-10">
          <div className="flex flex-col items-center">
            <StaticImage
              src="../../assets/home/solution-cogs.png"
              alt="Programming Solution"
              width={160}
              height={160}
              className="h-20 w-20"
            />
            <SectionHeading
              content="Giải pháp lập trình"
              className="w-full items-center"
            />
          </div>
          <div className="flex flex-row flex-wrap justify-center gap-8">
            {programmingSolutionPosts.map((post, index) => {
              const {Image, title} = post;
              return (
                <CardWrapper
                  key={index}
                  className={`${TailwindTransitions.default} flex cursor-pointer flex-col gap-1 rounded-lg bg-white/60 hover:bg-white`}
                  isShadow
                >
                  {Image}
                  <div className="p-3 text-base font-bolder text-gray-800 hover:text-primary md:text-lg">
                    {title}
                  </div>
                </CardWrapper>
              );
            })}
          </div>
        </div>

        {/* Domain Solution */}
        <div className="flex flex-col gap-10">
          <div className="flex flex-col items-center">
            <StaticImage
              src="../../assets/home/solution-cloud.png"
              alt="Domain Solution"
              width={160}
              height={160}
              className="h-20 w-20"
            />
            <SectionHeading
              content="Tên miền - Hosting - Cloud"
              className="w-full items-center"
            />
          </div>
          <div className="flex flex-row flex-wrap justify-center gap-8">
            {cloudSolutionPosts.map((post, index) => {
              const {Image, title} = post;
              return (
                <CardWrapper
                  key={index}
                  className={`${TailwindTransitions.default} flex cursor-pointer flex-col gap-1 rounded-lg bg-white/60 hover:bg-white`}
                  isShadow
                >
                  {Image}
                  <div className="p-3 text-base font-bolder text-gray-800 hover:text-primary md:text-lg">
                    {title}
                  </div>
                </CardWrapper>
              );
            })}
          </div>
        </div>
      </div>
    </BoxWrapper>
  );
};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({data: {wpPage}}) => {
  return (
    <Seo
      yoastSeo={wpPage?.seo}
      title={wpPage?.title}
      uri={wpPage?.uri}
      description={wpPage?.content && wpPage.content.substring(150)}
    />
  );
};

export const pageQuery = graphql`
  query {
    wpPage(uri: {eq: "/dich-vu"}) {
      id
      uri
      title
      content
      seo {
        fullHead
        canonical
      }
    }
  }
`;

const ButtonIcon = () => <HiOutlineArrowLongRight className="h-6 w-6" />;
export default SoftwareSolutionPage;
